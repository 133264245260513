import React, { useState } from "react";
import {
  CheckCircle,
  ArrowRight,
  Calendar,
  ClipboardList,
  LineChart,
} from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Card, CardContent } from "@/components/ui/card";
// import Pricing from "./Pricing";
// import Faqs from "./FAQ";
import Reviews from "./Reviews";
import OverlayModal from "@components/overlay/OverlayModal";
import { getWindow } from "@utils/helpers";
import { Spinner } from "@chakra-ui/react";

const JoinButton = () => {
  const [showSpinner, setShowSpinner] = useState(false);

  return (
    <button
      type="submit"
      className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex mx-auto gap-2"
      onClick={() => {
        if (window.ml && typeof window.ml === "function") {
          window.scrollTo(0, 0);
          window.ml("show", "g5GX28", true);
          setShowSpinner(true);
          setTimeout(() => {
            setShowSpinner(false);
          }, 5000);
        }
      }}
    >
      Join Waitlist
      {!showSpinner ? <ArrowRight size={20} /> : <Spinner />}
    </button>
  );
};

const LandingManagers = () => {
  const [showNotice, setShowNotice] = useState(
    !!getWindow()?.location?.href?.includes("/confirm")
  );

  // const [email, setEmail] = useState('');
  // const [isSubmitted, setIsSubmitted] = useState(false);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setIsSubmitted(true);
  //   setEmail('');
  // };

  return (
    <>
      {showNotice ? (
        <OverlayModal
          header={"Signup Successful 🎉"}
          title={"Thanks for joining!"}
          onClose={() => setShowNotice(false)}
        >
          <div className="flex flex-col items-center">
            <p className="text-center text-lg">
              We'll be in touch soon with early access.
            </p>
          </div>
        </OverlayModal>
      ) : null}
      <div className="min-h-screen bg-gradient-to-b from-slate-50 to-slate-100">
        {/* Hero Section */}
        <div className="container mx-auto px-4 pt-20 pb-16 text-center">
          <h1 className="text-5xl font-bold text-slate-900 mb-6">
            Transform Your 1:1 Meetings from
            <span className="text-blue-600"> Good to Great</span>
          </h1>
          <p className="text-xl text-slate-600 mb-8 max-w-2xl mx-auto">
            The intelligent meeting assistant that helps managers build stronger
            teams through better one-on-ones. Join 1,000+ managers already on
            the waitlist.
          </p>

          {/* Waitlist Form */}
          <div className="max-w-md mx-auto mb-12">
            {
              // !isSubmitted ? (
              //   <form onSubmit={handleSubmit} className="flex gap-2">
              //     <input
              //       type="email"
              //       value={email}
              //       onChange={(e) => setEmail(e.target.value)}
              //       placeholder="Enter your work email"
              //       className="flex-1 px-4 py-3 rounded-lg border border-slate-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              //       required
              //     />
              <JoinButton />
              //   </form>
              // ) : (
              //   <Alert className="bg-green-50 border-green-200">
              //     <AlertDescription className="text-green-800">
              //       Thanks for joining! We'll be in touch soon with early access.
              //     </AlertDescription>
              //   </Alert>
              // )
            }
          </div>

          {/* Social Proof */}
          <div className="text-sm text-slate-500 flex items-center justify-center gap-8 mb-12 -sm:flex-col">
            <span>Used by managers at</span>
            <div className="flex justify-center gap-8">
              <span className="font-semibold">Microsoft</span>
              <span className="font-semibold">Amazon</span>
              <span className="font-semibold">Outreach</span>
            </div>
          </div>

          {/* <div className="-sm:flex-col text-sm text-slate-500 flex items-center justify-center gap-8 mb-16">
          <span>Integrated with</span>
          <div className="flex-wrap justify-center children:ml-3">
            <span className="font-semibold">MS Teams</span>
            <span className="font-semibold">Slack</span>
            <span className="font-semibold">Google Meet</span>
            <span className="font-semibold">Zoom</span>
          </div>
          <div className="flex-wrap justify-center children:ml-3 lg:ml-[-2rem]">
            <span className="font-semibold">Jira</span>
            <span className="font-semibold">Notion</span>
            <span className="font-semibold">Hubspot</span>
            <span className="font-semibold">Confluence</span>
          </div>
        </div> */}

          {/* Feature Cards */}
          <div
            className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto"
            id="features"
          >
            <Card className="text-left">
              <CardContent className="pt-6">
                <div className="mb-4">
                  <ClipboardList className="h-8 w-8 text-blue-600" />
                </div>
                <h3 className="text-xl font-semibold mb-2">
                  Never Drop the Ball
                </h3>
                <p className="text-slate-600">
                  Smart action tracking ensures nothing falls through the
                  cracks. Automated follow-ups and reminders keep everyone
                  accountable.
                </p>
              </CardContent>
            </Card>

            <Card className="text-left">
              <CardContent className="pt-6">
                <div className="mb-4">
                  <Calendar className="h-8 w-8 text-blue-600" />
                </div>
                <h3 className="text-xl font-semibold mb-2">
                  Build on the Past
                </h3>
                <p className="text-slate-600">
                  Searchable meeting history and smart context tracking help you
                  spot patterns and maintain continuity across conversations.
                </p>
              </CardContent>
            </Card>

            <Card className="text-left">
              <CardContent className="pt-6">
                <div className="mb-4">
                  <LineChart className="h-8 w-8 text-blue-600" />
                </div>
                <h3 className="text-xl font-semibold mb-2">
                  Perfect Your Process
                </h3>
                <p className="text-slate-600">
                  Customizable templates and AI-powered prompts help you run
                  consistent, meaningful 1:1s while keeping conversations
                  natural.
                </p>
              </CardContent>
            </Card>
          </div>

          <Reviews />

          <div className="mx-auto">
            <JoinButton />
          </div>

          {/* Benefits List */}
          <div className="max-w-2xl mx-auto mt-16 text-left" id="why">
            <h2 className="text-2xl font-bold mb-6 text-center">
              Why Managers Love Our Solution
            </h2>
            <div className="grid gap-4 mx-auto justify-center">
              {[
                "83% of users report improved meeting effectiveness",
                "Save 2+ hours per week on meeting prep and follow-up",
                "Increase team engagement and retention through better 1:1s",
                "Early access pricing - 50% off for waitlist members",
              ].map((benefit, i) => (
                <div key={i} className="flex items-center gap-3 ">
                  <CheckCircle className="h-5 w-5 text-green-600 flex-shrink-0" />
                  <span className="text-slate-700">{benefit}</span>
                </div>
              ))}
            </div>
          </div>

          {/* <Pricing /> */}
        </div>
      </div>
    </>
  );
};

export default LandingManagers;

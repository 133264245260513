import {
  appName,
  getWindow,
  primaryColor,
  baseUrl,
  APP_CONST,
} from "@utils/index";
import logo from "@assets/logo-2.svg";
import { MdBolt } from "react-icons/md/index";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Spinner } from "@chakra-ui/react";

declare global {
  interface Window {
    // set window.ml
    ml: (
      action: string,
      id: string,
      options?: boolean | Record<string, unknown>
    ) => void;
  }
}

export const AppLogo = () => {
  return (
    <div>
      <a href={`${baseUrl.landing}/`}>
        <div className="flex flex-row">
          <img className="h-7 sm:h-9 md:h-10 mr-2" src={logo} alt="logo" />
          <p className="text-[#2563EB] tracking-widest font-bold  text-lg sm:text-xl md:text-2xl lg:text-3xl">
            {appName}
          </p>
        </div>
      </a>
    </div>
  );
};

export default function Navbar() {
  const [showSpinner, setShowSpinner] = useState(false);

  const toggleMailerLite = () => {
    if (window.ml && typeof window.ml === "function") {
      window.scrollTo({ top: 0, behavior: "smooth" });
      window.ml("show", "g5GX28", true);
      setShowSpinner(true);
      setTimeout(() => {
        setShowSpinner(false);
      }, 5000);
    }
  };

  return (
    <div className="h-16 lg:h-[75px] px-12 -lg:px-9 -md:px-6 -sm:px-5 sticky top-0 z-50 flex-center-y justify-between bg-white">
      <AppLogo />

      {/* <div className="flex-center-y gap-[72px]"> */}
      <ul className="-md:hidden flex gap-10">
        <li className="-xl:hidden">
          <Link to={`${baseUrl.landing}/`}>Home</Link>
        </li>
        <li className="-lg:hidden">
          <a href={`${baseUrl.landing}/#features`}>Features</a>
        </li>
        {/* <li className="-lg:hidden">
          <a href={`${baseUrl.landing}/#pricing`}>Pricing</a>
        </li> 
        <li className="-lg:hidden">
          <a href={`${baseUrl.landing}/#faq`}>FAQ</a>
        </li> */}
        <li className="-lg:hidden">
          <a href={`${baseUrl.landing}/#why`}>Why</a>
        </li>
        <li className="-xl:hidden">
          <Link to={`${baseUrl.landing}/contact`}>Contact</Link>
        </li>
      </ul>

      <div className="flex gap-3 sm:gap-4 md:gap-5 text-xs sm:text-sm md:text-base lg:text-lg font-bold">
        <button
          // href={`${baseUrl.landing}/#pricing`}
          onClick={toggleMailerLite}
          className="h-8 sm:h-10 md:h-[50px] w-24 sm:w-[120px] md:w-[150px] flex-center rounded-full text-[#2563EB] border border-[#eaecf0]"
        >
          {!showSpinner ? "Join Waitlist" : <Spinner />}
        </button>
        <a
          href={`${baseUrl.landing}/contact`}
          // onClick={toggleMailerLite}
          className="h-8 sm:h-10 md:h-[50px] w-24 sm:w-[120px] md:w-[150px] -sm:hidden flex-center rounded-full text-white bg-[#2563EB]"
        >
          Log In
        </a>
      </div>
      {/* </div> */}
    </div>
  );
}
